<template>
  <section
    class="h-auto px-6 md:px-6 lg:px-36 2xl:px-[340px] pt-20 lg:pt-20 leaderboard-bg overflow-hidden"
    id="leaderboards"
  >
    <div
      v-motion-pop-visible
      class="flex flex-col items-center justify-center gap-8"
    >
      <div
        class="flex flex-col justify-center items-center relative mt-0 md:mt-[60px] gap-y-10"
      >
        <h1
          class="text-white absolute -top-[30px] sm:-top-[26px] md:-top-[50px] lg:-top-[40px] xl:-top-[60px] text-[8px] xs:text-[10px] sm:text-[12px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-roboto uppercase"
          v-motion-pop-visible
        >
          **Need to wager $500 minimum to get leaderboard rewards
        </h1>
        <div class="relative flex items-center justify-center">
          <!-- The Leaderboard Container -->
          <div
            class="relative flex justify-center items-end w-[320px] xs:w-[440px] sm:w-[560px] md:w-[680px] lg:w-[720px] xl:w-[980px]"
          >
            <!-- The Image -->
            <img
              src="../assets/leaderboard-header.png"
              class="w-full"
              alt="leaderboard-header"
            />

            <!-- The Timer -->
            <p
              class="font-russo-one uppercase text-[5px] tracking-[0.3em] xs:text-[7px] sm:text-[10px] md:text-[12px] lg:text-[12px] xl:text-[18px] absolute mb-[26px] xs:mb-[35px] sm:mb-[44px] md:mb-[56px] lg:mb-[60px] xl:mb-[80px]"
              v-motion-pop-visible
            >
              {{ timer }}
            </p>
          </div>
        </div>

        <!-- <h1
        class="font-black-hans-sans text-[20px] md:text-[30px]"
        v-motion-pop-visible
      >
        Coming Soon....
      </h1> -->
      </div>
      <div
        class="flex flex-col items-center justify-center gap-4 px-8 md:justify-start md:items-start md:flex-row font-lato"
      >
        <div
          v-motion-pop-visible
          class="bg-gradient-to-r from-[#00FFF6] to-[#5B48FF] p-[1px] rounded-xl w-[200px] h-[40px] md:w-full md:h-[56px]"
        >
          <button
            @click="
              activeLeaderboard = 1;
              changeLeaderboard();
            "
            :class="{
              'bg-gradient-to-r from-[#17D5DE] to-[#9a35f8]':
                activeLeaderboard === 1,
            }"
            class="h-full text-white bg-[#04011C] font-extrabold w-full font-base pt-[4px] pb-[6px] px-8 md:px-9 relative uppercase rounded-xl"
          >
            <img
              src="../assets/roobet-logo.png"
              class="w-full object-contain h-[30px] md:w-[160px] md:h-[40px]"
            />
          </button>
        </div>
        <!-- 
        <div
          v-motion-pop-visible
          class="w-[200px] h-[40px] md:w-full md:h-full bg-gradient-to-r from-[#00FFF6] to-[#FF00D9] p-[1px] rounded-xl drop-neon"
        >
          <button
            @click="
              activeLeaderboard = 2;
              changeLeaderboard();
            "
            :class="{
              'bg-gradient-to-r from-[#00FFF6] to-[#FF00D9]':
                activeLeaderboard === 2,
            }"
            class="h-full text-white bg-[#04011C] w-full font-extrabold font-base pb-4 md:pt-2 px-8 md:px-10 relative uppercase rounded-xl"
          >
            <img
              src="../assets/csgobig-logo.png"
              class="w-full object-contain h-[30px] md:w-[160px]"
            />
          </button>
        </div>
        -->
      </div>
    </div>
    <div v-if="activeLeaderboard == 1">
      <RoobetLeaderboardSection ref="roobetSection" @update-dates="setDates" />
    </div>
    <!-- 
    <div v-if="activeLeaderboard == 2">
      <CsgobigLeaderboardSection
        ref="csgobigSection"
        @update-dates="setDates"
      />
    </div>
    -->
  </section>
</template>

<script>
import RoobetLeaderboardSection from "@/sections/RoobetLeaderboardSection.vue";
// import CsgobigLeaderboardSection from "@/sections/CsgobigLeaderboardSection.vue";
import moment from "moment-timezone";
export default {
  components: {
    RoobetLeaderboardSection,
    // CsgobigLeaderboardSection,
  },
  data() {
    return {
      activeLeaderboard: 1,
      timer: "",
      currentStartDate: "",
      currentEndDate: "",
      timerInterval: null,
    };
  },
  methods: {
    changeLeaderboard() {
      this.clearTimer();
      if (this.activeLeaderboard === 1) {
        this.$refs.roobetSection.init();
      }
      // else if (this.activeLeaderboard === 2) {
      //   this.$refs.csgobigSection.init();
      // }
    },
    setDates(startDate, endDate) {
      console.log("Dates received:", { startDate, endDate });

      const parseDate = (date) => {
        // Check if the date is already in ISO format
        if (moment(date, moment.ISO_8601, true).isValid()) {
          return moment.utc(date).toISOString();
        }

        // If not, assume format like 'November 22' and append year + time
        const currentYear = new Date().getFullYear();
        return moment
          .utc(`${date} ${currentYear} 00:00:00`, "MMMM DD YYYY HH:mm:ss")
          .toISOString();
      };

      this.currentStartDate = parseDate(startDate);
      this.currentEndDate = parseDate(endDate);

      this.startTimer();
    },
    startTimer() {
      const updateTimer = () => {
        const nowUtc = moment.utc();
        const currentStart = moment.utc(this.currentStartDate);
        const currentEnd = moment.utc(this.currentEndDate);

        if (nowUtc.isBefore(currentStart)) {
          const distance = currentStart.diff(nowUtc);
          const duration = moment.duration(distance);
          this.timer = `Starts in ${Math.floor(
            duration.asDays()
          )}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
        } else if (nowUtc.isBefore(currentEnd)) {
          const distance = currentEnd.diff(nowUtc);
          const duration = moment.duration(distance);
          this.timer = `${Math.floor(
            duration.asDays()
          )}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
        } else {
          this.timer = "";
        }
      };

      updateTimer();
      this.timerInterval = setInterval(updateTimer, 1000);
    },
    clearTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    mounted() {
      this.startTimer();
    },
    beforeDestroy() {
      this.clearTimer();
    },
  },
};
</script>

<style scoped>
.leaderboard-bg {
  background-image: url("../assets/leaderboard-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.font-russo-one {
  font-family: "Russo One", sans-serif;
}
</style>
