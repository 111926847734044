<template>
  <!-- Leaderboard Section -->
  <section
    :class="loading == true ? 'h-screen' : 'h-auto'"
    class="container mx-auto"
  >
    <div class="flex justify-center">
      <div
        class="relative flex flex-col items-center justify-center px-8 rounded-3xl"
      >
        <div
          v-if="loading == true"
          class="text-2xl text-center text-white md:text-4xl mt-[86px]"
          style="font-family: Titan One, sans-serif"
          v-motion-pop-visible
        >
          Loading Leaderboard...
        </div>
        <h1
          class="font-black-hans-sans text-center text-[10px] xs:text-[14px] sm:text-[18px] md:text-[22px] lg:text-[26px] xl:text-[30px] mt-[80px]"
          v-motion-pop-visible
          v-if="loading == false"
        >
          All wagers are counted during the inclusive dates: <br />
          {{ currentStartDate }} -
          {{ currentEndDate }}
        </h1>
        <img
          v-if="loading == false"
          src="../assets/leaderboard-table.png"
          class="hidden md:flex w-[330px] xs:w-[440px] sm:w-[600px] md:w-[680px] lg:w-[940px] xl:w-[1100px] mt-[100px]"
          alt="leaderboard-table"
          v-motion-slide-visible-left
        />
        <div
          v-if="loading == false"
          class="hidden md:flex overflow-x-hidden w-[260px] xs:w-[360px] sm:w-[570px] md:w-[560px] lg:w-[700px] xl:w-[900px] absolute top-0 left-[88px] xs:left-[110px] sm:left-[164px] md:left-[266px] lg:left-[250px] xl:left-[26.4%] 2xl:left-[28.4%] md:mt-[244px] lg:mt-[254px] xl:mt-[274px]"
        >
          <table
            v-motion-slide-visible-left
            class="min-w-full h-[110px] xs:h-[156px] sm:h-[200px] md:h-[200px] lg:h-[310px] xl:h-[354px] bg-transparent font-saira-condensed"
          >
            <thead>
              <tr>
                <th
                  class="px-4 text-left w-[30%] xs:w-[32%] sm:w-[26%] md:w-[26%] lg:w-[26%] xl:w-[18%]"
                ></th>
                <th
                  class="px-4 text-left w-[22%] xs:w-[24%] sm:w-[16%] md:w-[22%] lg:w-[22%] xl:w-[18%]"
                ></th>
                <th
                  class="px-4 text-left w-[130px] md:w-[40px] lg:w-[40%]"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="h-[30px] xs:h-[38px] sm:h-[5vh] md:h-[80px] lg:h-[90px]"
                v-if="currentTop3.length > 0"
              >
                <td
                  class="text-[10px] xs:text-[20px] sm:text-[18px] md:text-[26px] lg:text-[44px] xl:text-[48px] font-bold uppercase text-center"
                >
                  {{
                    currentTop3.length > 0
                      ? getMaskedUsername(currentTop3[0].username)
                      : ""
                  }}
                </td>
                <td
                  class="text-[#5EDDE9] text-[10px] xs:text-[18px] sm:text-[18px] md:text-[26px] lg:text-[36px] xl:text-[42px] font-bold"
                >
                  PRIZE: ${{ getRewardValue(0) }}
                </td>
                <td
                  class="pl-[30px] xs:pl-[42px] sm:pl-[64px] md:pl-[78px] lg:pl-[78px] xl:pl-[116px] text-[7px] xs:text-[9px] sm:text-[12px] md:text-[18px] lg:text-[22px] xl:text-[30px] font-bold"
                >
                  WAGERED: <br />
                  <span
                    >${{
                      currentTop3.length > 0
                        ? currentTop3[0].weightedWagered.toFixed(2)
                        : ""
                    }}</span
                  >
                </td>
              </tr>
              <tr
                class="h-[30px] xs:h-[36px] sm:h-[5vh] md:h-[66px] lg:h-[70px] xl:h-[100px] text-[8px] xs:text-[7px] sm:text-[10px] md:text-[11px]"
                v-if="currentTop3.length > 0"
              >
                <td
                  class="text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[36px] xl:text-[40px] font-bold uppercase text-center"
                >
                  {{
                    currentTop3.length > 0
                      ? getMaskedUsername(currentTop3[1].username)
                      : ""
                  }}
                </td>
                <td
                  class="text-[#5EDDE9] text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[34px] xl:text-[34px] font-bold"
                >
                  PRIZE: ${{ getRewardValue(1) }}
                </td>
                <td
                  class="text-[6px] xs:text-[8px] sm:text-[10px] md:text-[14px] lg:text-[20px] xl:text-[22px] pl-[14px] xs:pl-[18px] sm:pl-[36px] md:pl-[48px] lg:pl-[38px] xl:pl-[64px] font-bold"
                >
                  WAGERED: <br />
                  <span
                    >${{
                      currentTop3.length > 0
                        ? currentTop3[1].weightedWagered.toFixed(2)
                        : ""
                    }}</span
                  >
                </td>
              </tr>
              <tr
                class="h-[28px] sm:h-[4.8vh] md:h-[86px] lg:h-[100px] xl:h-[90px]"
                v-if="currentTop3.length > 0"
              >
                <td
                  class="text-[7px] xs:text-[12px] sm:text-[15px] md:text-[20px] lg:text-[28px] xl:text-[32px] font-bold uppercase text-center"
                >
                  {{
                    currentTop3.length > 0
                      ? getMaskedUsername(currentTop3[2].username)
                      : ""
                  }}
                </td>
                <td
                  class="text-[#5EDDE9] text-[7px] xs:text-[10px] sm:text-[12px] md:text-[20px] lg:text-[26px] xl:text-[32px] font-bold"
                >
                  PRIZE: ${{ getRewardValue(2) }}
                </td>
                <td
                  class="pl-0 sm:pl-[4px] md:pl-[14px] lg:pl-0 xl:pl-[12px] md:text-[14px] lg:text-[18px] xl:text-[22px] font-bold text-start"
                >
                  WAGERED: <br />
                  <span class="pl-0"
                    >${{
                      currentTop3.length > 0
                        ? currentTop3[2].weightedWagered.toFixed(2)
                        : ""
                    }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <!-- Ranks -->

          <div
            v-if="loading == false"
            class="flex flex-col items-center justify-center w-full mt-10"
          >
            <div class="w-full xs:w-[400px] sm:w-full bg-transparent">
              <table
                class="min-w-full sm:w-[540px] md:w-[700px] lg:w-[800px] xl:w-[1000px] bg-transparent font-saira-condensed"
              >
                <thead>
                  <tr v-motion-slide-visible-bottom>
                    <th
                      class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                    >
                      RANK
                    </th>
                    <th
                      class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                    >
                      USERNAME
                    </th>
                    <th
                      class="font-acme font-thin px-4 py-2 text-left w-[30vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                    >
                      WAGERED
                    </th>
                    <th
                      class="font-acme font-thin px-4 py-2 text-left text-[14px] sm:text-[16px] md:text-[14px] lg:text-[16px]"
                    >
                      PRIZE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in currentTop7"
                    :key="index"
                    class="hidden md:table-row h-[50px]"
                    v-motion-slide-visible-bottom
                  >
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                    >
                      {{ getOrdinalSuffix(index + 4) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                    >
                      {{ getMaskedUsername(item.username) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                    >
                      ${{ item.weightedWagered.toFixed(2) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                    >
                      ${{ getRewardValue(index + 3) }}
                    </td>
                  </tr>
                  <tr
                    v-for="(item, index) in currentTop10"
                    :key="index"
                    class="table-row md:hidden h-[50px]"
                    v-motion-slide-visible-bottom
                  >
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                    >
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                    >
                      {{ getMaskedUsername(item.username) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                    >
                      ${{ item.weightedWagered.toFixed(2) }}
                    </td>
                    <td
                      class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                    >
                      ${{ getRewardValue(index) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="flex flex-col justify-center items-start w-full bg-white bg-opacity-10 h-[200px] p-4 mt-8 text-[10px] xs:text-[10px] sm:text-[12px] md:w-[700px] md:text-[12px] lg:text-[14px] lg:w-[800px] xl:text-[16px] xl:w-[1000px]"
              v-motion-slide-visible-bottom
            >
              <p class="uppercase font-roboto">
                Your wagers on Roobet will count towards the leaderboard at the
                following weights based on the games you are playing. This helps
                prevent leaderboard abuse:
              </p>
              <div class="flex flex-col mt-4 uppercase">
                <p>
                  *Games with an RTP under 96% will contribute 100% of the
                  amount wagered to the leaderboard.
                </p>
                <p>
                  *Games with an RTP of 97% will contribute 50% of the amount
                  wagered to the leaderboard.
                </p>
                <p>
                  *Games with an RTP of 98- 99% and above will contribute 10% of
                  the amount wagered to the leaderboard.
                </p>
                <p>*Only house games and slots count towards leaderboard.</p>
              </div>
            </div>
          </div>
        </div>
        <section
          v-if="!loading"
          class="flex flex-col justify-start bg-no-repeat bg-contain bg-top md:bg-center items-center h-[860px] sm:h-[880px] md:h-[1160px] lg:h-[1400px] w-full bg-transparent overflow-hidden pt-[60px] pb-[20px] md:pb-0 px-4 xs:px-6 sm:px-10 relative"
        >
          <div
            class="flex flex-col justify-center items-center relative mt-0 md:mt-[60px] gap-y-10"
          >
            <img
              src="../assets/previous-leaderboard-header.png"
              class="w-[320px] xs:w-[440px] sm:w-[580px] md:w-[680px] lg:w-[900px] xl:w-[1100px]"
              alt="leaderboard-header"
              v-motion-pop-visible
            />
            <h1
              class="font-black-hans-sans text-center text-[10px] xs:text-[14px] sm:text-[18px] md:text-[22px] lg:text-[26px] xl:text-[30px]"
              v-motion-pop-visible
              v-if="loading == false"
            >
              All wagers are counted during the inclusive dates: <br />
              {{ prevStartDate }} -
              {{ prevEndDate }}
            </h1>
            <!-- <h1
        class="font-black-hans-sans text-[20px] md:text-[30px]"
        v-motion-pop-visible
      >
        Coming Soon....
      </h1> -->
          </div>
          <div class="relative">
            <div
              v-if="loading == true"
              v-motion-pop-visible
              class="mt-10 text-2xl text-center text-white md:text-4xl"
              style="font-family: Titan One, sans-serif"
            >
              Loading Leaderboard...
            </div>
            <img
              v-if="loading == false"
              src="../assets/leaderboard-table.png"
              class="hidden md:flex w-[330px] xs:w-[440px] sm:w-[600px] md:w-[680px] lg:w-[800px] xl:w-[1100px] mt-[100px]"
              alt="leaderboard-table"
              v-motion-slide-visible-left
            />

            <!-- Leaderboard Details -->

            <div
              v-if="loading == false"
              class="hidden md:flex overflow-x-hidden w-[260px] xs:w-[360px] sm:w-[570px] md:w-[560px] lg:w-[700px] xl:w-[900px] absolute top-0 left-[88px] xs:left-[110px] sm:left-[164px] md:left-[180px] lg:left-[210px] xl:left-[246px] md:mt-[100px] lg:mt-[70px] xl:mt-[80px]"
            >
              <table
                v-motion-slide-visible-left
                class="min-w-full h-[110px] xs:h-[156px] sm:h-[200px] md:h-[200px] lg:h-[310px] xl:h-[354px] bg-transparent font-saira-condensed"
              >
                <thead>
                  <tr>
                    <th
                      class="px-4 text-left w-[30%] xs:w-[32%] sm:w-[26%] md:w-[26%] lg:w-[10%] xl:w-[14%]"
                    ></th>
                    <th
                      class="px-4 text-left w-[22%] xs:w-[24%] sm:w-[16%] md:w-[22%] lg:w-[16%] xl:w-[14%]"
                    ></th>
                    <th
                      class="px-4 text-left w-[130px] md:w-[40px] lg:w-[40%]"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-[30px] xs:h-[38px] sm:h-[5vh] md:h-[80px] lg:h-[130px] xl:h-[100px]"
                    v-if="prevTop3.length > 0"
                  >
                    <td
                      class="text-[10px] xs:text-[20px] sm:text-[18px] md:text-[26px] lg:text-[38px] xl:text-[48px] font-bold uppercase text-center"
                    >
                      {{
                        prevTop3.length > 0
                          ? getMaskedUsername(prevTop3[0].username)
                          : ""
                      }}
                    </td>
                    <td
                      class="text-[#5EDDE9] text-[10px] xs:text-[18px] sm:text-[18px] md:text-[26px] lg:text-[30px] xl:text-[34px] font-bold"
                    >
                      PRIZE: ${{ getRewardValue(0) }}
                    </td>
                    <td
                      class="pl-[30px] xs:pl-[42px] sm:pl-[64px] md:pl-[84px] lg:pl-[74px] xl:pl-[108px] text-[7px] xs:text-[9px] sm:text-[12px] md:text-[18px] lg:text-[22px] xl:text-[30px] font-bold"
                    >
                      WAGERED: <br />
                      <span
                        >${{
                          prevTop3.length > 0
                            ? prevTop3[0].weightedWagered.toFixed(2)
                            : ""
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr
                    class="h-[30px] xs:h-[36px] sm:h-[5vh] md:h-[66px] lg:h-[20px] xl:h-[40px] text-[8px] xs:text-[7px] sm:text-[10px] md:text-[11px]"
                    v-if="prevTop3.length > 0"
                  >
                    <td
                      class="text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[36px] xl:text-[40px] font-bold uppercase text-center"
                    >
                      {{
                        prevTop3.length > 0
                          ? getMaskedUsername(prevTop3[1].username)
                          : ""
                      }}
                    </td>
                    <td
                      class="text-[#5EDDE9] text-[8px] xs:text-[16px] sm:text-[15px] md:text-[22px] lg:text-[30px] xl:text-[34px] font-bold"
                    >
                      PRIZE: ${{ getRewardValue(1) }}
                    </td>
                    <td
                      class="text-[6px] xs:text-[8px] sm:text-[10px] md:text-[14px] lg:text-[18px] xl:text-[22px] pl-[14px] xs:pl-[18px] sm:pl-[36px] md:pl-[50px] lg:pl-[34px] xl:pl-[64px] font-bold"
                    >
                      WAGERED: <br />
                      <span
                        >${{
                          prevTop3.length > 0
                            ? prevTop3[1].weightedWagered.toFixed(2)
                            : ""
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr
                    class="h-[28px] sm:h-[4.8vh] md:h-[80px] lg:h-[110px] xl:h-[80px]"
                    v-if="prevTop3.length > 0"
                  >
                    <td
                      class="text-[7px] xs:text-[12px] sm:text-[15px] md:text-[20px] lg:text-[28px] xl:text-[34px] font-bold uppercase text-center"
                    >
                      {{
                        prevTop3.length > 0
                          ? getMaskedUsername(prevTop3[2].username)
                          : ""
                      }}
                    </td>
                    <td
                      class="text-[#5EDDE9] text-[7px] xs:text-[10px] sm:text-[12px] md:text-[20px] lg:text-[26px] xl:text-[32px] font-bold"
                    >
                      PRIZE: ${{ getRewardValue(2) }}
                    </td>
                    <td
                      class="pl-0 sm:pl-[4px] md:pl-[18px] lg:pl-0 xl:pl-[12px] md:text-[14px] lg:text-[18px] xl:text-[22px] font-bold text-start"
                    >
                      WAGERED: <br />
                      <span class="pl-0"
                        >${{
                          prevTop3.length > 0
                            ? prevTop3[2].weightedWagered.toFixed(2)
                            : ""
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <!-- Ranks -->

            <div
              v-if="loading == false"
              class="flex flex-col items-center justify-center w-full mt-10"
            >
              <div class="w-full xs:w-[400px] sm:w-full bg-transparent">
                <table
                  class="min-w-full sm:w-[540px] md:w-[700px] lg:w-[800px] xl:w-[1000px] bg-transparent font-saira-condensed"
                >
                  <thead>
                    <tr v-motion-slide-visible-bottom>
                      <th
                        class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                      >
                        RANK
                      </th>
                      <th
                        class="font-acme font-thin px-4 py-2 text-left w-[40vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                      >
                        USERNAME
                      </th>
                      <th
                        class="font-acme font-thin px-4 py-2 text-left w-[30vw] text-[14px] sm:text-[14px] lg:text-[16px]"
                      >
                        WAGERED
                      </th>
                      <th
                        class="font-acme font-thin px-4 py-2 text-left text-[14px] sm:text-[16px] md:text-[14px] lg:text-[16px]"
                      >
                        PRIZE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in prevTop7"
                      :key="index"
                      class="hidden md:table-row h-[50px]"
                      v-motion-slide-visible-bottom
                    >
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                      >
                        {{ getOrdinalSuffix(index + 4) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                      >
                        {{ getMaskedUsername(item.username) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                      >
                        ${{ item.weightedWagered.toFixed(2) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                      >
                        ${{ getRewardValue(index + 3) }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in prevTop10"
                      :key="index"
                      class="table-row md:hidden h-[50px]"
                      v-motion-slide-visible-bottom
                    >
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[10px] sm:text-[12px] md:text-[16px]"
                      >
                        {{ getOrdinalSuffix(index + 1) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                      >
                        {{ getMaskedUsername(item.username) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                      >
                        ${{ item.weightedWagered.toFixed(2) }}
                      </td>
                      <td
                        class="font-saira-condensed font-bold text-[#5EDDE9] px-4 py-2 text-[12px] xs:text-[14px] sm:text-[16px] md:text-[16px]"
                      >
                        ${{ getRewardValue(index) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div
          class="flex flex-col justify-center items-start w-full bg-[#100E45] h-[200px] p-4 mt-8 text-[10px] xs:text-[10px] sm:text-[12px] md:w-[700px] md:text-[14px] lg:text-[14px] lg:w-[800px] xl:text-[16px] xl:w-[1000px]"
          v-motion-slide-visible-bottom
        >
          <p class="uppercase font-roboto">
            Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:
          </p>
          <div class="flex flex-col mt-4 uppercase">
            <p>
              *Games with an RTP under 96% will contribute 100% of the amount
              wagered to the leaderboard.
            </p>
            <p>
              *Games with an RTP of 97% will contribute 50% of the amount
              wagered to the leaderboard.
            </p>
            <p>
              *Games with an RTP of 98- 99% and above will contribute 25% of the
              amount wagered to the leaderboard.
            </p>
          </div>
        </div> -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "RoobetLeaderboardSection",
  data() {
    return {
      currentTop3: [],
      currentTop7: [],
      currentTop10: [],

      currentStartDate: "",
      currentEndDate: "",

      prevTop3: [],
      prevTop7: [],
      prevTop10: [],

      prevStartDate: "",
      prevEndDate: "",

      notFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;

      await axios.get(`${baseUrl}/api/leaderboard/roobet`).then((res) => {
        const leaderboard = res.data.leaderboard;

        this.currentTop3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.currentTop7 = leaderboard.slice(3, 10).map((item) => {
          return item;
        });

        this.currentTop10 = leaderboard.slice(0, 10).map((item) => {
          return item;
        });
        this.currentStartDate = this.formatDate(res.data.startDate);
        this.currentEndDate = this.formatDate(res.data.endDate);
        this.loading = false;

        this.notFormattedEndDate = res.data.endDate;

        this.$emit("update-dates", res.data.startDate, res.data.endDate);
      });

      await axios
        .get(`${baseUrl}/api/leaderboard/roobet?previous=true`)
        .then((rest) => {
          this.previousLeaderboard = rest.data.leaderboard;

          this.prevTop3 = this.previousLeaderboard.slice(0, 3).map((item) => {
            return item;
          });

          this.prevTop7 = this.previousLeaderboard.slice(3, 10).map((item) => {
            return item;
          });

          this.prevTop10 = this.previousLeaderboard.slice(0, 10).map((item) => {
            return item;
          });
          this.prevStartDate = this.formatDate(rest.data.startDate);
          this.prevEndDate = this.formatDate(rest.data.endDate);
          this.loading = false;
        });

      this.loading = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "2-digit",
      }).format(date);
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      const prizes = [200, 100, 75, 50, 25, 25, 15, 10, 5, 5];

      return prizes[rank];
    },
    getPrevRewardValue(rank) {
      const prizes = [200, 100, 75, 50, 25, 25, 15, 10, 5, 5];

      return prizes[rank];
    },

    getMaskedUsername(username) {
      return username.substring(0, 5) + "*****";
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
